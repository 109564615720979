import * as React from "react"
import { graphql } from "gatsby"
import BlogLayout from "../../components/word/BlogLayout"
import WordList, {
  GraphResponse,
  Nodes,
  QueryData,
} from "../../components/word/WordList"

export default function WordIndexHome({ data }: GraphResponse): JSX.Element {
  const { allMdx }: QueryData = data
  const { nodes }: Nodes = allMdx
  return (
    <BlogLayout pageTitle={"word"}>
      <WordList nodes={nodes} />
    </BlogLayout>
  )
}

export const query = graphql`
  {
    allMdx(sort: { fields: frontmatter___date, order: DESC }) {
      nodes {
        frontmatter {
          categories
          date
          slug
          subtitle
          teaser
          title
          url
        }
        excerpt
      }
    }
  }
`
