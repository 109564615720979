import * as React from "react"
import { Frontmatter } from "./BlogLayout"
import { Box, Flex, Heading, Text } from "@chakra-ui/react"
import { Link as GatsbyLink } from "gatsby"
import { Icon } from "@chakra-ui/icons"
import { MdOutlineToday } from "react-icons/md"
import WordTag from "./WordTag"

export interface Node {
  frontmatter: Frontmatter
  excerpt: string
}

export interface Nodes {
  nodes: Node[]
}

export interface QueryData {
  allMdx: Nodes
}

export interface GraphResponse {
  data: QueryData
}

export default function WordList({ nodes }: Nodes): JSX.Element {
  return (
    <Flex
      flexDirection={"column"}
      className={"word-list-component"}
      padding={4}
    >
      {nodes.map((post: Node, idx: number) => {
        let dtpost = new Date(post.frontmatter.date)
        let date = dtpost.toDateString()
        return (
          <Box py={2} key={idx} as={"section"}>
            <Heading className={"title"} size={"lg"}>
              <GatsbyLink to={post.frontmatter.url}>
                {post.frontmatter.title}
              </GatsbyLink>
            </Heading>
            <Box p={1}>
              <Flex
                alignItems={"center"}
                className={"date-categories"}
                py={1}
                flexWrap={"wrap"}
              >
                <Icon as={MdOutlineToday} mr={1} />
                <Text
                  className={"datetime"}
                  fontSize={"sm"}
                  whiteSpace={"nowrap"}
                >
                  {date}
                </Text>
                {post.frontmatter.categories && (
                  <Text fontSize={"sm"} px={1}>
                    in
                  </Text>
                )}
                {post.frontmatter.categories &&
                  post.frontmatter.categories.map(
                    (category: string, idx: number) => {
                      // the empty space returned is significant for text-based browsers/crawlers
                      return (
                        <span key={idx}>
                          <WordTag
                            text={category}
                            kind={"category"}
                            size={"sm"}
                            mr={1}
                          />{" "}
                        </span>
                      )
                    }
                  )}
              </Flex>

              <Text className={"subtitle"} fontSize={"lg"} py={1}>
                {post.frontmatter.subtitle}
              </Text>
              <Text className={"teaser"} py={1}>
                {post.frontmatter.teaser || post.excerpt}
              </Text>
            </Box>
          </Box>
        )
      })}
    </Flex>
  )
}
